
import { useUiStore } from '@/stores/ui'
export default {
  name: 'MPLandingApplications',
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  computed: {
    mobileImage() {
      return `images/landing/screens_${this.$i18n.locale}.png`
    },

    image() {
      return `images/landing/screens_${this.$i18n.locale}.png`
    },
  },
}
