
import { useMarketStore } from '~/stores/market'
import startObserverLoading from '@/mixins/startObserverLoading'
import { useCustomer } from '@/stores/customer'
import { useDcmStore } from '@/stores/dcm'

export default {
  name: 'MPLandingCategories',
  mixins: [startObserverLoading],
  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    const dcmStore = useDcmStore()
    return {
      marketStore,
      dcmStore,
      customerStore,
    }
  },
  data() {
    return {
      isLandingCategoriesVisible: false,
    }
  },
  async fetch() {
    if (this.customerStore.isBot) {
      await this.marketStore.getPopularCategories()
      if (this.marketStore.popularCategoriesList.length) {
        this.isLandingCategoriesVisible = true
      }
    }
  },
  computed: {
    sortedBanners() {
      return (
        this.dcmStore.partnerPromos?.items
          ?.slice()
          .sort((a, b) => a.seq_no - b.seq_no) || []
      )
    },
  },
  async mounted() {
    if (!this.$device.isCrawler) {
      this.startObserverLoading(
        this.$refs.LandingCategories,
        this.showLandingCategories,
      )
    }
  },

  methods: {
    handleCategoryClick(category) {
      console.log('category', category)
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'select_promotion',
          creative_slot: 'homepage_actual_today',
          creative_url: this.url(category),
          publish_date: null,
          unpublish_date: null,
          location_code: null,
          object: 'card',
          creative_id: category?.id,
          category_ids: `${category?.category_id || ''}`,
        })
      }
    },
    handlePromoClick(banner) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'select_promotion',
          creative_slot: this.dcmStore.partnerPromos?.location_code,
          creative_url: banner?.action_url,
          publish_date: banner?.active_from,
          unpublish_date: banner?.active_to,
          location_code: this.dcmStore.partnerPromos?.location_code,
          object: 'banner',
          creative_id: banner?.id,
          category_ids: banner?.category_id
            ? `${this.banner.category_id}`
            : null,
        })
      }
      if (banner.action_url && banner.is_clickable) {
        window.location.href = banner.action_url
      }
    },
    async showLandingCategories() {
      await this.marketStore.getPopularCategories()
      await this.dcmStore.getPartnerPromos()
      if (this.marketStore.popularCategoriesList.length) {
        this.isLandingCategoriesVisible = true
      }
    },
    url(category) {
      return this.localePath({
        name: 'categories-slug',
        params: {
          slug: `${category?.category_id}-${category?.slugged_name}`,
        },
      })
    },
  },
}
